import React from 'react';
import { ALIGN, COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Heading5 } from '@latitude/heading';
import { Box } from '@latitude/box';
import Image from '@latitude/image';
import { Filter } from '@latitude/filter';
import {
  RetailerCard,
  RetailerCardLogo,
  RetailerCardTitle,
  RetailerCardLocation
} from './_styled';

const RetailerList = ({ filteredRetailers, categories }) => {
  return (
    <React.Fragment>
      <Box.Section backgroundColor={COLOR.GREY6}>
        <Filter categories={categories}>
          {filteredRetailers
            .sort((a, b) => {
              // Sort by numerical order
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              // Sort alphabetically
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;

              return 0;
            })
            .map((retailer, i) => {
              /*
               * Return relevant retail channel (e.g. Online, In-Store or both)
               */
              const getRetailerChannels = () => {
                const categories = retailer.locations;
                const isInStore = categories.includes('In-Store')
                  ? 'In-Store'
                  : 'Online';

                if (
                  categories.includes('Online') &&
                  categories.includes('In-Store')
                ) {
                  return 'Online & In-Store';
                }

                return isInStore;
              };

              return (
                <RetailerCard
                  key={i}
                  noStyle
                  href={retailer.url}
                  trackEventData={{ label: retailer.name }}
                  locations={retailer.locations}
                >
                  <RetailerCardLogo>
                    {retailer.logo && <Image src={require(`../../../images/partner-logos/${retailer.logo}`)} />}
                  </RetailerCardLogo>
                  <RetailerCardLocation>
                    {getRetailerChannels()}
                  </RetailerCardLocation>
                  <RetailerCardTitle>
                    <Heading5 align={ALIGN.CENTER} marginBottom={MARGIN.M0}>
                      {retailer.name}
                    </Heading5>
                  </RetailerCardTitle>
                </RetailerCard>
              );
            })}
        </Filter>
      </Box.Section>
    </React.Fragment>
  );
};
export default RetailerList;
