/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
import { Heading1 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { List } from '@latitude/list';
import imgSearchIcon from './images/search-icon.svg';
import imgConfettiOne from './images/confetti-1.svg';
import imgConfettiTwo from './images/confetti-2.svg';
import imgSizzles from './images/flourish-sizzles.svg';

export const StyledHero = styled(Box)`
  background-image: none,
    radial-gradient(circle, rgb(232, 234, 248) 0%, rgb(245, 246, 252) 100%);
  background-position: 0 20px, 0, 0;
  background-repeat: no-repeat;
  background-size: 600px, 100%, 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1.875rem;
  position: relative;
  margin-top: 80px;

  @media (min-width: ${BREAKPOINT.MD}) {
    min-height: 350px;
  }

  strong {
    border-bottom: 10px solid ${COLOR.PINK_LIGHT};
    position: relative;

    &:after {
      content: '';
      background: center / contain url(${imgSizzles}) no-repeat;
      height: 28px;
      position: absolute;
      right: -30px;
      top: -10px;
      width: 28px;
    }
  }
`;

export const StyledHeroImage = styled.div`
  bottom: 0;
  position: absolute;
  max-width: 1400px;
  margin: auto;
  width: 100%;

  img {
    display: none;

    @media (min-width: ${BREAKPOINT.XL}) {
      height: 320px;
      display: block;
    }
  }
`;

export const StyledHeading = styled(Heading1)`
  position: relative;
  text-align: center;
  margin-bottom: 2.5rem;

  @media (max-width: ${BREAKPOINT.SM}) {
    font-size: 1.625rem;
    line-height: 1.75;
    max-width: 210px;
  }

  &:after,
  &:before {
    content: '';
    height: 60px;
    top: -8px;
    position: absolute;
    width: 60px;
  }

  &:before {
    background: center / contain no-repeat url(${imgConfettiOne});
    left: -50px;
  }

  &:after {
    background: center / contain no-repeat url(${imgConfettiTwo});
    right: -50px;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    &:after,
    &:before {
      height: 80px;
      top: -14px;
      width: 80px;
    }

    &:before {
      left: -90px;
    }

    &:after {
      right: -90px;
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    &:after,
    &:before {
      height: 120px;
      top: -28px;
      width: 120px;
    }

    &:before {
      left: -120px;
    }

    &:after {
      right: -140px;
    }
  }
`;

export const Search = styled.input`
  padding: 14px 66px 14px 18px;
  border: none;
  width: 100%;
  max-width: 325px;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background: ${COLOR.WHITE} url(${imgSearchIcon}) no-repeat right 14px center;
  z-index: 1;

  ::placeholder {
    color: ${COLOR.BLACK};
  }
`;

export const StyledList = styled(List)`
  text-align: left;
  li {
    position: relative;
    list-style: none;
    margin: 0 0 ${MARGIN.M8} ${MARGIN.M8};
    &:before {
      font-size: 36px;
      position: absolute;
      top: -12px;
      left: -${MARGIN.M24};
      color: ${COLOR.BLUE_LIGHT};
      content: '•';
    }
  }
`;

export const RetailerCard = styled(Link)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
`;

export const RetailerCardLogo = styled(Flex)`
  height: 154px;
  justify-content: center;
  align-items: center;
  padding: ${PADDING.P40};

  img {
    max-height: 114px;
  }

  /* ie11 bugfix */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    img {
      flex-shrink: 0;
    }
  }
`;

export const RetailerCardLocation = styled.div`
  text-align: center;
  color: rgb(89 89 89 / 65%);
`;

export const RetailerCardTitle = styled(Flex)`
  flex-grow: 1;
  margin: 0;
  padding: ${PADDING.P16};
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-top: solid 1px ${COLOR.GREY5};
  @media (max-width: ${BREAKPOINT.MD}) {
    padding: 12px;

    h5 {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;
