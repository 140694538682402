import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Metadata } from '@latitude/metadata';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import { COLOR, FONT_WEIGHT } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import withStickyState from '../../hoc/withStickyState';
import { SITE_URL } from '../../utils/constants';

import {
  StyledHero,
  StyledHeroImage,
  StyledHeading,
  Search
} from './where-to-shop/_styled';
import RetailerList from './where-to-shop/_retailer-list';
import data from './where-to-shop/_data.json';
import bgHeroImage from './where-to-shop/images/bg-hero.png';

const WhereToShopPage = props => {
  const TITLE_SUFFIX = 'Gem by Latitude';

  // const hasMounted = useHasMounted();
  const [analytics] = useContext(AnalyticsContext);
  const [filteredRetailers, setFilteredRetailers] = useState(data.retailers);
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function findAllCategories(retailers) {
    let result0 = [];
    if (retailers) {
      retailers.forEach(item => {
        const categories = item.categories;

        if (categories) {
          categories.forEach(item => {
            result0.push(item);
          });
        }
      });
      result0 = result0.filter(onlyUnique).sort();
    }

    const result1 = [];
    result0.forEach(item => {
      result1.push({
        value: item,
        label: item
      });
    });
    return result1;
  }

  function doNameFilterChange(event) {
    const { value } = event.target;
    setNameFilter(value);
  }

  function doCategoryFilterChange(selectedOption) {
    const value = selectedOption ? selectedOption.value : null;
    setCategoryFilter(value);
  }

  function matchesRetailerName(retailer) {
    if (nameFilter) {
      return retailer.name.toLowerCase().includes(nameFilter.toLowerCase());
    }
    return true;
  }

  function matchesRetailerCategory(retailer) {
    if (categoryFilter) {
      return (
        retailer.categories && retailer.categories.includes(categoryFilter)
      );
    }
    return true;
  }

  function filterRetailers(retailer) {
    return matchesRetailerName(retailer) && matchesRetailerCategory(retailer);
  }

  function trackSearchEvent() {
    if (nameFilter) {
      trackEvent(analytics, {
        category: 'where-to-shop',
        action: 'interaction',
        label: 'Search by store name'
      });
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const filtered = data.retailers.filter(retailer => {
        return filterRetailers(retailer);
      });
      setFilteredRetailers(filtered);
      trackSearchEvent();
    }, 400);

    return () => {
      return clearTimeout(timeout);
    };
  }, [nameFilter, categoryFilter]);

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer where-to-shop-page">
        <Metadata
          title={`Interest Free shopping | ${TITLE_SUFFIX}`}
          description="View our range of Interest Free options available at thousands of stores across Australia. Payment plans include Buy Now, Pay Later and Instalment Interest Free."
          canonical={SITE_URL + '/credit-cards/where-to-shop/'}
        />

        <StyledHero>
          <StyledHeroImage>
            <img src={bgHeroImage} alt="Meet the stores we love" />
          </StyledHeroImage>
          <StyledHeading fontWeight={FONT_WEIGHT.NORMAL} color={COLOR.BLACK}>
            Meet the stores <strong>we love</strong>
          </StyledHeading>
          <Search
            value={nameFilter}
            onChange={doNameFilterChange}
            placeholder="Search by store name"
          />
          <Select
            className="SelectCategories"
            options={findAllCategories(data.retailers)}
            onChange={doCategoryFilterChange}
            isClearable
            placeholder="Select category..."
            styles={{
              placeholder: provided => ({
                ...provided,
                color: '#000',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 600
              }),
              control: provided => ({
                ...provided,
                marginTop: '5pt',
                padding: '3pt 5pt',
                border: 'none',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 600,
                width: '325px',
                zIndex: 1
              })
            }}
          />
        </StyledHero>

        <RetailerList
          filteredRetailers={filteredRetailers}
          categories={data.categories}
        />
      </main>
    </Layout>
  );
};

const WhereToShopPageWithSticky = withStickyState(WhereToShopPage);

export default WhereToShopPageWithSticky;
